$blurb-border-color: #ddd;
$mini-font-size: 75%;
$note-font-size: 110%;
$post-it-yellow: #FFFFA5;

blurb {

  md-list-item.blurb {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  p {
    white-space: pre-line;
  }

  .mini-to {
    font-size: $mini-font-size;
  }

  .mini-stamp {
    font-size: $mini-font-size;
  }

  .moi-button {
    margin-left: 24px;
    margin-right: 24px;
  }

  h5 {
    margin-top: 0;
    margin-bottom: 0;
  }

  /**
   * Float the count over the notes button above.
   *
   * I do not actually use this. I just wanted to keep it for future reference.
   *
   */
  .notes-count {
    position: relative;
    top: -12px;
    left: -16px;
    background: lightgrey;
    border-radius: 50%;
    padding: 4px;
    min-width: 24px;
    text-align: left;
    font-size: 80%;
  }

  .inline-note {
    background-color: $post-it-yellow;
    padding: 8px;
    margin: 8px;
    font-size: $note-font-size;
    font-style: italic;
    white-space: pre-line;
  }

}

.incoming-blurb {
  border-left-style: solid;
  border-left-width: medium;
  border-color: $blurb-border-color;
}

.outgoing-blurb {
  border-right-style: solid;
  border-right-width: medium;
  border-color: $blurb-border-color;
  text-align: right;
}
