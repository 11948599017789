app {
  /*
   * The right-sidenav will be used for composing things like messages and
   * notes so I made the right-sidenav bigger than the standard 304px.
   */
  .md-sidenav-right,
  .md-sidenav-right.md-locked-open,
  .md-sidenav-right.md-closed.md-locked-open-add-active {
    min-width: 304px !important;
    width: 85vw !important;
    max-width: 550px !important;
  }

  .title {
    font-weight: bold;
  }
}
