$initial-on-width: 36px;
$initial-on-height: 36px;
$initial-on-color: lightblue;
$initial-on-margin: $initial-on-width/4;

initial-on {
  .initials {
    text-align: center;
    margin: $initial-on-margin;
    width: $initial-on-width;
    height: $initial-on-height;
    background-color: $initial-on-color;
    border-radius: 50%;
  }
}
