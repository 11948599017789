buddy-dex {

  md-list {
    /* Scroll the butty-dex independently */
    overflow-y: scroll;

    md-list-item {
      .md-button {
        /* Angular Materiel capitalizes button text by default.
        * Override the capitalization to display case-sensitive scmids. */
        text-transform: none;

        md-truncate {
          /* Align the scmid to the left, right beside the avatar icon and
          * far away from the count chip */
          text-align: left;
        }
      } /* .md-button */

      .selected {
        /* If the button is selected then boldify the text.
         * The color is also changed but inside of javascript. */
        font-weight: bold;
      }

      .unread {
        /* The unread count and ago info should discreet. */
        font-size: 80%;
      }
    } /* md-list-item */
  } /* md-list */

  .full-compose-button {
    position: absolute;
    bottom: 16px;
    right: 32px;
    /* z-index: 100; */
  }
}
