.login-form {
  .title-header {
    color: white;
    text-align: center;
  }

  .error-message {
    color: white;
    text-align: center;
    font-size: 88%;
  }

  .google-play {
    img {
      max-width: 110px;
    }
  }

  .app-store {
    img {
      max-width: 100px;
    }
  }

}
