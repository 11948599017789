contact-whom {
  /* nothing */
  width: 100%;
  flex-shrink: 0;

  /**
   * The md-autocomplete drop down is too narrow to display the scmid and name.
   *
   * Setting:
   *
   *     md-autocomplete-snap="width"
   *
   * in the md-chips helped.
   *
   * So I have commented out the the following...
   */
  /*.md-chips .md-chip-input-container {
    width: 100%;
  } */

  /**
   * The md-autocomplete drop down is too narrow to display the scmid and name.
   *
   * Setting:
   *
   *     md-autocomplete-snap="width"
   *
   * in the md-chips helped.
   *
   * So I have commented out the the following...
   */
  /* .md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
    width: 100%;
  } */
}
