.full-compose-dialog {
  /* IE11 scrunches the width so I force 90% width. */
  width: 90%;

  /* Android scrunches too so I put 360px minimum. */
  min-width: 360px;

  /* Safety against going beyond the width of screen. */
  max-width: 100%;

  min-height: 300px;

  md-dialog-content {
    /* IE11 is  too eager to overflow-y. So I place a minimum height. */
    min-height: 180px;
  }

  /*
   * angular-material places below the textarea/input an error message div.
   * Let the .md-errors-spacer take up 0 vertical space.
   */
  .md-errors-spacer {
    min-height: 0px;
    height: 0px;
  }
}
