.mini-submit {

  /*
   * angular-material places below the textarea/input an error message div.
   * Let the .md-errors-spacer take up 0 vertical space.
   */
  .md-errors-spacer {
    min-height: 0px;
    height: 0px;
  }

  /*
   * angular-material places space between the md-input-container.  I get rid
   * of the extra space for the container holding the button.
   */
  .send-button-container {
    padding-left: 12px;
  }
}
